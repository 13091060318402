<template>
    <img :src="order.feature_image ? order.feature_image : $global.no_image" alt="Car Image" width="546" height="364" />
    <h2>{{ order.make.name }} {{ order.car_model.name }} {{ order.version ?? '' }}</h2>
    <div class="payment-right-price">
        <div v-if="order.without_vat === 1">
            <p>Μερικό σύνολο:</p>
            <p>{{ order.partial_price.toLocaleString('el-GR') }}€</p>
        </div>
        <div>
            <p>Έξοδα χρηματοδότησης:</p>
            <p>{{ order.finance ? order.finance : 0 }}€</p>
        </div>
        <div>
            <p>Κόστος μεταφοράς:</p>
            <p>{{ order.shipping ? order.shipping : 0 }}€</p>
        </div>
        <div v-if="order.without_vat === 1">
            <p>ΦΠΑ:</p>
            <p>{{ order.final_vat.toLocaleString('el-GR') }}€</p>
        </div>
        <div v-if="coupon.new_price">
            <p>Έκπτωση ({{ coupon.coupon_code }}):</p>
            <p>-{{ (order.final_price - coupon.new_price).toLocaleString('el-GR') }}€</p>
        </div>
        <div>
            <p>Σύνολο:</p>
            <p>{{ coupon.new_price ? coupon.new_price.toLocaleString('el-GR') : order.final_price.toLocaleString('el-GR') }}€</p>
        </div>
    </div>
    <div class="payment-right-total">
        <p>{{ paid ? `Ποσό που κρατήθηκε` : `Ποσό κράτησης` }}</p>
        <p>{{ order.amount.toLocaleString('el-GR') }}€</p>
    </div>
</template>

<script>
    export default {
        props: {
            order: Object,
            paid: Boolean,
            coupon: Object
        },
        setup(props, { slots, attrs }) {
        },
        data() {
            return {
            }
        },
        methods: {
        },
    }
</script>