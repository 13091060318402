<template>
    <Head>
        <title>AutoBesikos - Μεταχειρισμένα σαν καινούρια</title>
        <meta name="description" content="Στην AutoBesikos, πιστεύουμε ότι κάθε πελάτης αξίζει να απολαμβάνει την εμπειρία της οδήγησης ενός αυτοκινήτου που συνδυάζει την αξιοπιστία, την ποιότητα και την αίσθηση του καινούριου, ακόμα και όταν πρόκειται για μεταχειρισμένο.">
    </Head>

    <div class="single-base page-metaxeirismena">
        <section class="single-head">
            <Link href="/">Αρχική σελίδα</Link>
            <p>/</p>
            <h1>Μεταχειρισμένα σαν καινούρια</h1>
        </section>
        <section class="single-hero">
            <img src="/images/metaxeirismena-san-kainouria.jpg" alt="Μεταχειρισμένα σαν καινούρια" width="1920" height="535" />
            <div class="single-hero-text">
                <h2>Μεταχειρισμένα σαν καινούρια</h2>
            </div>
        </section>
        <section class="single-top">
            <h2>Η Εγγύηση Ποιότητας από την AutoBesikos</h2>
            <div class="single-top-divider">
                <p>Στην <strong>AutoBesikos</strong>, πιστεύουμε ότι κάθε πελάτης αξίζει να απολαμβάνει την εμπειρία της οδήγησης ενός αυτοκινήτου που συνδυάζει την αξιοπιστία, την ποιότητα και την αίσθηση του καινούριου, ακόμα και όταν πρόκειται για μεταχειρισμένο. Αυτό που μας κάνει να ξεχωρίζουμε είναι η δέσμευσή μας για απόλυτη διαφάνεια και κορυφαία ποιότητα, υποστηριζόμενη από την μοναδική <strong>5πλή Εγγύηση AutoBesikos</strong>.</p>
                <div class="single-top-buttons">
                    <Button anchor outline href="#form-interest">Επικοινωνία</Button>
                    <Button anchor inline href="/vehicles?mileage[to]=20000&condition[]=Μεταχειρισμένο"><span>Δες αυτοκίνητα</span> <component :is="services[1].img" width="23" height="23" /></Button>
                </div>
            </div>
        </section>
        <section class="single-services">
            <h2 class="single-heading">Με την υπογραφή της <span>5πλής</span> Εγγύησης</h2>
            <div class="single-services-main">
                <div v-for="(item, index) in services" :key="index">
                    <component :is="item.img" width="70" height="70" />
                    <h2>{{ item.title }}</h2>
                    <p>{{ item.text }}</p>
                </div>
            </div>
        </section>
        <section class="single-metaxeirismena" v-if="cars?.data.length > 0">
            <div class="single-metaxeirismena-slider">
                <h2 class="single-heading">Ανακαλύψτε μεταχειρισμένα σαν <span>καινούρια</span></h2>
                <SliderNewCars :cars="cars.data" :user="$attrs.auth.user" :perView="breakpointsCars" @favorite_car="updateFavorites" />
                <Button anchor inline href="/vehicles?mileage[to]=20000&condition[]=Μεταχειρισμένο">Δες περισσότερα αυτοκίνητα</Button>
            </div>
        </section>
        <section class="single-row">
            <div class="single-row-item">
                <img src="/images/metaxeirismena-san-kainouria-1.jpg" width="589" height="380" alt="Η Αίσθηση του καινούριου σε κάθε διαδρομή">
                <div>
                    <h2>Η Αίσθηση του καινούριου σε κάθε διαδρομή</h2>
                    <p>Η εξοικονόμηση χρημάτων δεν σημαίνει ότι πρέπει να κάνετε εκπτώσεις στην ποιότητα. Στην AutoBesikos, διασφαλίζουμε ότι κάθε μεταχειρισμένο όχημα προσφέρει την αίσθηση, την εμφάνιση και την αξιοπιστία ενός καινούριου αυτοκινήτου, με σημαντικά χαμηλότερο κόστος. Έτσι, απολαμβάνετε το καλύτερο από δύο κόσμους: την υψηλή ποιότητα και την προσιτή τιμή.</p>
                </div>
            </div>
            <div class="single-row-item">
                <div>
                    <h2>Εμπειρία αγοράς που ξεχωρίζει</h2>
                    <p>Αυτό που κάνει την AutoBesikos μοναδική, είναι η προσήλωσή μας να παρέχουμε μια εμπειρία αγοράς που ξεπερνά τις προσδοκίες σας. Από τη στιγμή που θα περιηγηθείτε στο site μας ή θα επισκεφθείτε τις εκθέσεις μας, θα νιώσετε τη διαφορά.<br /><br />Στόχος μας είναι να κάνουμε την επιλογή σας εύκολη και απολαυστική, προσφέροντάς σας την αίσθηση ότι οδηγείτε ένα αυτοκίνητο που μοιάζει με καινούριο, σε κάθε λεπτομέρεια.</p>
                </div>
                <img src="/images/metaxeirismena-san-kainouria-2.jpg" width="589" height="380" alt="Η Αίσθηση του καινούριου σε κάθε διαδρομή">
            </div>
        </section>
        <section class="page-form">
            <div class="vehicle-heading">
                <h2>Θέλεις σήμερα να αποκτήσεις το νέο σου Αυτοκίνητο; Μη χάσεις την ευκαιρία - Επικοινώνησε μαζί μας.</h2>
            </div>
            <div class="vehicle-form-main" id="form-interest">
                <FormVehicle page="Μεταχειρισμένα σαν καινούρια" used />
            </div>
        </section>
    </div>

    <!-- Favorites -->
    <RefreshPageFavorites :user="$attrs.auth.user" :favorites_cars="favorites_cars" :favorites_cars_trigger="favorites_cars_trigger" @postChange="triggerFavorite" />
    <!-- End Favorites -->
</template>

<script>
    import Button from '../Components/Button/Button.vue';
    import FormVehicle from '../Components/Form/FormVehicle.vue';
    import { ServiceManual, ServiceKey, ServiceSale, ServiceStatus, ServiceSearch } from '../Icons/Icons'; 
    import SliderNewCars from "../Components/Slider/SliderNewCars.vue";
    import RefreshPageFavorites from "../Components/LocalStorage/RefreshPageFavorites.vue";

    export default {
        components: {
            Button, FormVehicle, ServiceManual, ServiceKey, ServiceSale, ServiceStatus, ServiceSearch, SliderNewCars, RefreshPageFavorites
        },
        props: {
            cars: Object
        },
        setup() {
            const services = [
                { img: ServiceManual, title: 'Εγγύηση ατρακάριστου', text: 'Επιλέγουμε μόνο αυτοκίνητα που δεν έχουν υποστεί σοβαρές ζημιές από ατυχήματα, προσφέροντάς σας ασφάλεια και σιγουριά.' },
                { img: ServiceKey, title: 'Εγγύηση 1 έτους/20.000 χλμ', text: 'Ηρεμία για τον δρόμο σας. Σας καλύπτουμε για κάθε πιθανή ανησυχία, προσφέροντας υποστήριξη για έναν χρόνο ή 20.000 χιλιόμετρα.' },
                { img: ServiceSale, title: 'Εγγύηση επιστροφής χρημάτων', text: 'Αν κάτι δεν ανταποκρίνεται στις προσδοκίες σας, μπορείτε να επιστρέψετε το αυτοκίνητο και να πάρετε πίσω τα χρήματά σας εντός 10 ημερών.' },
                { img: ServiceStatus, title: 'Εγγύηση πραγματικών χιλιομέτρων', text: 'Κάθε όχημα συνοδεύεται από απόλυτα επαληθευμένα και διαφανή χιλιόμετρα. Σας διασφαλίζουμε ότι γνωρίζετε ακριβώς την ιστορία του αυτοκινήτου που επιλέγετε.' },
                { img: ServiceSearch, title: 'Εγγύηση άρτιας τεχνικής κατάστασης', text: 'Κάθε όχημα περνά από λεπτομερείς τεχνικούς ελέγχους ώστε να παραδίδεται σε άψογη λειτουργική κατάσταση.' },
            ];

            const breakpointsCars = {
                '1600': { slidesPerView: 4 },
                '1400': { slidesPerView: 3.8 },
                '800': { slidesPerView: 2.8 },
                '600': { slidesPerView: 1.8 },
                '400': { slidesPerView: 1.4 },
            };

            return { services, breakpointsCars }
        },
        data() {
            return {
                update_favorites: 0,
                favorites_cars: [],
                favorites_cars_trigger: false,
            }
        },
        provide() {
            return {
                // reactive global provider
                updateFavorites: this.update_favorites,
            }
        },
        methods: {
            triggerFavorite(value) {
                // Observe the window changes and re-calculate the active favorites
                this.favorites_cars_trigger = value;
                this.favorites_cars.length = 0;
            },
            updateFavorites(id) {
                // Update the array
                if ( !this.favorites_cars.includes(id) ) {
                    this.favorites_cars.push(id);
                } else {
                    const index = this.favorites_cars.indexOf(id);
                    if ( index > -1 ) {
                        this.favorites_cars.splice(index, 1);
                    }
                }

                // Update between tabs - browser
                if ( this.favorites_cars.length > 0 ) {
                    this.favorites_cars_trigger = true;
                } else {
                    this.favorites_cars_trigger = false;
                }
            },
            stickyFloat() {
                const stick = window.pageYOffset || document.documentElement.scrollTop || window.scrollTop;
                const float = document.getElementsByClassName('single-top-buttons')[0];
                const ParentElement = document.querySelector(".single-hero");
                const menuHeight = document.querySelector(".header").offsetHeight;

                const maxHeight = document.querySelector('main').offsetHeight - window.innerHeight - document.querySelector('.page-form').offsetHeight;
                let parentElementTop = ( ParentElement.offsetTop + ParentElement.offsetHeight - menuHeight ) / 2;

                if ( stick >= parentElementTop && stick <= maxHeight ) {
                    float.classList.add('single-top-buttons-active');
                } else {
                    float.classList.remove('single-top-buttons-active');
                }
            }, 
        },
        mounted() {
            window.addEventListener("scroll", this.stickyFloat);
        },
        beforeUnmount() {
            window.removeEventListener("scroll", this.stickyFloat);
            
            if ( this.favorites_cars.length > 0  ) {
                axios.post(`${this.$global.origin}/account/favorites`, { ids: this.favorites_cars }).then((data) => { return data });
            }
        }
    }
</script>