<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="70.554" height="70.517" viewBox="0 0 70.554 70.517">
        <g data-name="properness status" transform="translate(-2.48 -2.5)">
            <path data-name="Path 13759" d="M71.179,42.594a1.837,1.837,0,0,0-1.855,1.855v1.372a5.543,5.543,0,0,1-4.488,5.49c-4.637.853-9.348,1.447-14.059,1.855V48.2a5.557,5.557,0,0,0-5.564-5.564H30.375A5.557,5.557,0,0,0,24.811,48.2v4.971c-4.711-.408-9.385-1.039-14.059-1.855a5.543,5.543,0,0,1-4.488-5.49V43.336l8.68,2.893a1.68,1.68,0,0,0,.594.111,1.843,1.843,0,0,0,.556-3.6l-9.867-3.3V37.958a5.521,5.521,0,0,1,3.079-4.971l.556-.3A8.757,8.757,0,0,0,11.642,31.5h11.24a1.855,1.855,0,1,0,0-3.709H14.276s.148-.3.223-.482L19.247,13.03a5.618,5.618,0,0,1,5.267-3.821h2.077a1.855,1.855,0,1,0,0-3.709H24.514a9.257,9.257,0,0,0-8.791,6.343L10.975,26.125V26.2l-5.3-5.3a1.862,1.862,0,1,0-2.634,2.634l5.527,5.527a2.029,2.029,0,0,1-.371.3l-.556.3a9.241,9.241,0,0,0-5.119,8.309V57.469a7.441,7.441,0,0,0,7.419,7.419H12.05a7.353,7.353,0,0,0,6.64-4.117l2.151-4.3a152.1,152.1,0,0,0,16.915.964,152.1,152.1,0,0,0,16.915-.964l2.151,4.3a7.385,7.385,0,0,0,6.64,4.08h2.114A7.441,7.441,0,0,0,73,57.432V44.449a1.837,1.837,0,0,0-1.855-1.855ZM15.426,59.1a3.665,3.665,0,0,1-3.3,2.04H10.01A3.72,3.72,0,0,1,6.3,57.432V53.2a8.928,8.928,0,0,0,3.821,1.706c2.263.408,4.563.779,6.862,1.076l-1.521,3.079ZM28.52,53.389v-5.23A1.86,1.86,0,0,1,30.375,46.3H45.213a1.837,1.837,0,0,1,1.855,1.855v5.23A154.7,154.7,0,0,1,28.52,53.389Zm37.094,7.753H63.5a3.665,3.665,0,0,1-3.3-2.04l-1.558-3.079c2.3-.3,4.6-.668,6.862-1.076a8.973,8.973,0,0,0,3.821-1.706v4.229a3.72,3.72,0,0,1-3.709,3.709Z" transform="translate(0 8.128)" fill="#ff3b00"/>
            <path data-name="Path 13760" d="M42.03,2.5H19.774A9.277,9.277,0,0,0,10.5,11.774V37.74a9.277,9.277,0,0,0,9.274,9.274H42.03A9.277,9.277,0,0,0,51.3,37.74V11.774A9.277,9.277,0,0,0,42.03,2.5Zm5.564,35.24A5.557,5.557,0,0,1,42.03,43.3H19.774a5.557,5.557,0,0,1-5.564-5.564V11.774a5.557,5.557,0,0,1,5.564-5.564H42.03a5.557,5.557,0,0,1,5.564,5.564Z" transform="translate(21.73 0)" fill="#ff3b00"/>
            <path data-name="Path 13761" d="M18.823,6.056,16.411,8.468,16.3,8.356a1.869,1.869,0,0,0-2.634,0,1.836,1.836,0,0,0,0,2.634l1.41,1.41a1.889,1.889,0,0,0,1.3.556,1.74,1.74,0,0,0,1.3-.556L21.382,8.69a1.862,1.862,0,0,0-2.634-2.634Z" transform="translate(28.801 8.128)" fill="#ff3b00"/>
            <path data-name="Path 13762" d="M25.774,6.5H18.355a1.855,1.855,0,1,0,0,3.709h7.419a1.855,1.855,0,1,0,0-3.709Z" transform="translate(37.986 10.838)" fill="#ff3b00"/>
            <path data-name="Path 13763" d="M18.823,10.056l-2.411,2.411-.111-.111a1.869,1.869,0,0,0-2.634,0,1.836,1.836,0,0,0,0,2.634l1.41,1.41a1.889,1.889,0,0,0,1.3.556,1.74,1.74,0,0,0,1.3-.556l3.709-3.709a1.862,1.862,0,1,0-2.634-2.634Z" transform="translate(28.801 18.966)" fill="#ff3b00"/>
            <path data-name="Path 13764" d="M25.774,10.5H18.355a1.855,1.855,0,0,0,0,3.709h7.419a1.855,1.855,0,0,0,0-3.709Z" transform="translate(37.986 21.676)" fill="#ff3b00"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "ServiceStatus",
        props: ["width", "height"],
    }
</script>