<template>
    <Head>
        <title>{{ title }}</title>
        <meta name="description" :content="metaDescription">
    </Head>
</template>


<script>
    import { router } from '@inertiajs/vue3';

    export default {
        components: {
        },
        props: {
            title: String,
            description: String,
            locale: String,
            noDesc: Boolean
        },
        setup(props, { slots, attrs }) {
            return { };
        },
        data() {
            return {
                metaDescription: this.description ?? '',
            };
        },
        watch: {
        },
        computed: {
        },
        methods: {
            currentDescription() {
                if ( this.description ) {
                    if ( this.description.length >= 153 ) { this.metaDescription = `${this.description.slice(0,153)}..` }
                }
            }
        },
        created() {
            this.currentDescription();
        },
        mounted() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
    }
</script>