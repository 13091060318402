<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="70.313" height="70.517" viewBox="0 0 70.313 70.517">
        <g data-name="car key" transform="translate(-2.47 -2.41)">
            <path data-name="Path 13765" d="M67.667,29.537l-.554-.259a5.122,5.122,0,0,1-1.441-1.034,1.855,1.855,0,1,0-2.623,2.623,9.269,9.269,0,0,0,2.4,1.736l.554.3a5.539,5.539,0,0,1,3.066,4.95v1.478l-9.826,3.288a1.881,1.881,0,0,0-1.182,2.327A1.817,1.817,0,0,0,59.8,46.2a1.126,1.126,0,0,0,.591-.111L69.034,43.2V45.68a5.452,5.452,0,0,1-4.47,5.43,136.933,136.933,0,0,1-14,1.847v-4.95a5.533,5.533,0,0,0-5.541-5.541H30.248a5.533,5.533,0,0,0-5.541,5.541v4.95c-4.691-.406-9.346-1.034-14-1.847a5.51,5.51,0,0,1-4.47-5.43V43.2l8.644,2.881a1.673,1.673,0,0,0,.591.111,1.835,1.835,0,0,0,.554-3.583L6.2,39.326V37.849A5.5,5.5,0,0,1,9.267,32.9l.554-.3a7.548,7.548,0,0,0,1.773-1.219H22.786a1.847,1.847,0,1,0,0-3.694h-8.57s.148-.3.222-.48l4.728-14.222a5.594,5.594,0,0,1,5.245-3.8h13.15a1.847,1.847,0,1,0,0-3.694H24.412a9.218,9.218,0,0,0-8.755,6.317L10.929,26.028V26.1L5.647,20.82a1.855,1.855,0,1,0-2.623,2.623l5.5,5.5a2.021,2.021,0,0,1-.369.3l-.554.3a9.2,9.2,0,0,0-5.1,8.274v19.43a7.41,7.41,0,0,0,7.388,7.388H12a7.322,7.322,0,0,0,6.612-4.1l2.142-4.285A151.459,151.459,0,0,0,37.6,57.2a151.459,151.459,0,0,0,16.844-.96l2.142,4.285A7.354,7.354,0,0,0,63.2,64.592H65.3A7.41,7.41,0,0,0,72.691,57.2V37.775a9.244,9.244,0,0,0-5.1-8.274ZM15.4,58.9a3.649,3.649,0,0,1-3.288,2.032H10.006a3.7,3.7,0,0,1-3.694-3.694V53.031a8.891,8.891,0,0,0,3.8,1.7c2.253.406,4.544.776,6.834,1.071l-1.515,3.066Zm13.039-5.689V48.007a1.852,1.852,0,0,1,1.847-1.847H45.061a1.829,1.829,0,0,1,1.847,1.847v5.208A154.05,154.05,0,0,1,28.438,53.215Zm36.939,7.72H63.272A3.649,3.649,0,0,1,59.984,58.9l-1.551-3.066c2.29-.3,4.58-.665,6.834-1.071a8.935,8.935,0,0,0,3.8-1.7v4.211a3.7,3.7,0,0,1-3.694,3.694Z" transform="translate(0 8.297)" fill="#ff3b00"/>
            <path data-name="Path 13766" d="M26.052,18.4l-14.48,14.48a5.373,5.373,0,0,0,0,7.609l1.293,1.293a5.371,5.371,0,0,0,3.8,1.588,4.864,4.864,0,0,0,1.256-.148A5.371,5.371,0,0,0,21.8,39.681a1.719,1.719,0,0,1,1.736-1.145,5.356,5.356,0,0,0,5.1-3.694l1.256-3.731a1.714,1.714,0,0,1,1.071-1.071l5.1-1.7,1.81,1.81a5.529,5.529,0,0,0,7.831,0l5.208-5.208a5.574,5.574,0,0,0,0-7.831L37.835,4.035A5.535,5.535,0,0,0,33.92,2.41h0A5.535,5.535,0,0,0,30,4.035L24.759,9.244a5.574,5.574,0,0,0,0,7.831l1.293,1.293Zm3.731,8.164a5.366,5.366,0,0,0-3.4,3.4L25.128,33.7a1.719,1.719,0,0,1-1.736,1.145,5.356,5.356,0,0,0-5.1,3.694,1.627,1.627,0,0,1-1.219,1.108,1.674,1.674,0,0,1-1.588-.443l-1.293-1.293a1.694,1.694,0,0,1,0-2.4l14.48-14.48,4.433,4.433-3.325,1.108Zm-2.4-14.7,5.245-5.208A1.881,1.881,0,0,1,33.92,6.1h0a1.733,1.733,0,0,1,1.293.554L48.289,19.734a1.829,1.829,0,0,1,0,2.623l-5.208,5.208a1.829,1.829,0,0,1-2.623,0l-2.623-2.623h0L33.92,21.027l-6.538-6.538a1.829,1.829,0,0,1,0-2.623Z" transform="translate(20.265 0)" fill="#ff3b00"/>
            <path data-name="Path 13767" d="M24.372,15.828a1.855,1.855,0,0,0,1.293.554,1.792,1.792,0,0,0,1.293-.554,1.829,1.829,0,0,0,0-2.623L19.127,5.374A1.855,1.855,0,1,0,16.5,8l7.831,7.831Z" transform="translate(36.314 6.492)" fill="#ff3b00"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "ServiceKey",
        props: ["width", "height"],
    }
</script>