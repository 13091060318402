<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '19.09'" :height="height ? height : '19.089'" viewBox="0 0 19.09 19.089">
        <path d="M4.273,19.1H.315V6.354H4.273ZM2.292,4.616A2.3,2.3,0,1,1,4.584,2.3,2.311,2.311,0,0,1,2.292,4.616ZM19.086,19.1H15.136V12.9c0-1.479-.03-3.375-2.058-3.375-2.058,0-2.373,1.606-2.373,3.268V19.1H6.752V6.354h3.8V8.093H10.6a4.159,4.159,0,0,1,3.745-2.058c4.005,0,4.742,2.638,4.742,6.064v7Z" transform="translate(0 -0.01)" fill="#fff"/>
    </svg>

</template>

<script>
    export default {
        name: "Linkedin",
        props: ["color", "width", "height"],
    }
</script>