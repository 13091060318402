<template>
    <div class="slider-new-cars">
        <swiper class="slider-new-cars-carousel" :class="noDetails && 'slider-new-cars-carousel-no-details'" :slides-per-view="1.5" :space-between="30" :centeredSlides="centeredSlides" :loop="true" :breakpoints="perView ? perView : breakpointsCars" :modules="modules" :lazy="true" :pagination="{ type: 'bullets', clickable: true }" :autoplay="{ delay: 4000, disableOnInteraction: true }">
            <swiper-slide v-for="(car, index) in cars" :key="`new-car-${index}`">
                <Car :car="car" :noDetails="noDetails" :user="$attrs.user" @favorite_car="updateFavorites" />
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
    import Car from "../Car/CarBlock.vue";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import { Pagination, Autoplay } from "swiper";
    import "swiper/css";
    import "swiper/css/pagination";

    export default {
        components: {
            Car, Swiper, SwiperSlide,
        },
        props: {
            cars: Array,
            noDetails: Boolean,
            perView: Object,
        },
        setup() {
            return { modules: [Pagination, Autoplay] }
        },
        emits: ['favorite_car'],
        computed: {
            breakpointsCars() {
                return {
                    '1600': { slidesPerView: 4.8 },
                    '1400': { slidesPerView: 3.8 },
                    '800': { slidesPerView: 2.8 },
                    '600': { slidesPerView: 1.8 },
                    '400': { slidesPerView: 1.4 },
                }
            },
            centeredSlides() {
                if ( window.innerWidth <= 768 ) {return true } else { return false }
            },
        },
        methods: {
            updateFavorites(id) {
                this.$emit('favorite_car', id)
            },
        }
    }
</script>