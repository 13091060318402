<template>
    <form class="form-interest" @submit.prevent="submitForm">
        <div class="form-text">
            <label for="name">Όνομα</label>
            <input type="text" id="name" v-model="form.name" placeholder="Εισάγετε το όνομα σας" required/>
            <InputError :message="errors.name" />
        </div>
        <div class="form-text">
            <label for="lname">Επώνυμο</label>
            <input type="text" id="lname" v-model="form.lname" placeholder="Εισάγετε το επώνυμό σας" required/>
            <InputError :message="errors.lname" />
        </div>
        <div class="form-text">
            <label for="phone">Τηλέφωνο</label>
            <input type="tel" id="phone" v-model="form.phone" placeholder="Εισάγετε το τηλέφωνό σας" required/>
            <InputError :message="errors.phone" />
        </div>
        <div class="form-text">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="form.email" placeholder="Εισάγετε το email σας" required/>
            <InputError :message="errors.email" />
        </div>
        <div class="form-text form-textarea">
            <label for="textarea">Σχόλια</label>
            <textarea id="textarea" v-model="form.comments" :placeholder="`πχ Ενδιαφέρομαι για ${interestFor}, παρακαλώ πολύ επικοινωνήστε μαζί μου`"></textarea>
        </div>
        <div class="form-interest-bottom">
            <div>
                <input type="checkbox" v-model="form.checkBox" :id="`checkbox-${page}`" required/>
                <label :for="`checkbox-${page}`" >Αποδέχομαι την επικοινωνία με την AutoBesikos</label>
            </div>
            <div class="desktop-only">
                <Button @click="submitForm" outline submit :processing="processing" >Αποστολή</Button>
            </div>
            <div class="mobile-only">
                <Button @click="submitForm" inline submit :processing="processing" >Αποστολή</Button>
            </div>
        </div>
        <InputError :message="errors.checkbox" />
    </form>
</template>

<script>
    import { useForm } from "@inertiajs/vue3";
    import InputError from "./InputError.vue";
    import Button from "../Button/Button.vue";

    export default {
        components: {
            Button, InputError,
        },
        props: {
            interestFor: String,
            carID: Number,
            page: String,
            bottom: Boolean,
            used: Boolean
        },
        setup(props, { slots, attrs }) {
            const form = useForm({
                name: '',
                lname: '',
                phone: '',
                email: '',
                page: props.page,
                comments: '',
                checkBox: false,
                slug: window.location.pathname.replace(/\//g, ''),
            });

            const errors = useForm({
                name: '',
                lname: '',
                phone: '',
                email: '',
                checkbox: '',
            });

            return { form, errors };
        },
        data() {
            return {
                processing: false,
                checkBoxMessage: 'Πρέπει να αποδεχτείς την επικοινωνία με την AutoBesikos',
            }
        },
        methods: {
            submitForm() { 
                if ( this.form.checkBox ) {
                    this.processing = true;
                    axios.post(this.used ? `/used-like-new` : this.carID ? `/forms/car/${this.carID}` : this.bottom ? `/forms/page` : `/forms/contact`, 
                        this.form
                    ).then((response) => {
                        // Clear values
                        this.form.reset();
                        this.errors.reset();
                        this.processing = false;
                        this.$helper.getFormMessage(true, this.$global.message, true, 'Η φόρμα στάλθηκε', 'green', response, this.carID ? 'vehicle' : this.$global.current_pathname);
                    }).catch((error) => {
                        const getErrors = Object.entries(error.response.data.errors);
                        this.errors.reset();
                        this.processing = false;
                        getErrors.map((e) => { this.errors[e[0]] = e[1][0]; })
                        if (getErrors.length > 0) {
                            getErrors.map((e) => { this.errors[e[0]] = e[1][0]; })
                        } else {
                            this.$helper.getFormMessage(false, this.$global.message, true, 'Κάτι πήγε στραβά', 'red', error);
                        }
                    })
                } else {
                    this.errors.checkbox = this.checkBoxMessage;
                }
            },
        },
    }
</script>