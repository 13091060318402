<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="66.842" height="70.517" viewBox="0 0 66.842 70.517">
        <g data-name="car manual" transform="translate(-3.5 -2.5)">
            <path data-name="Path 13752" d="M51.968,26.447,56.31,22.1a1.863,1.863,0,0,0-2.635-2.635l-5.122,5.122-2.227-8.87a5.6,5.6,0,0,0-5.419-4.231H24.429a5.6,5.6,0,0,0-5.419,4.231l-2.227,8.87L11.662,19.47A1.863,1.863,0,0,0,9.027,22.1l4.342,4.342A5.536,5.536,0,0,0,8.507,31.94v7.423a5.56,5.56,0,0,0,5.567,5.567H15.93v1.856a1.856,1.856,0,0,0,3.711,0V44.93h25.98v1.856a1.838,1.838,0,0,0,1.856,1.856h0a1.838,1.838,0,0,0,1.856-1.856V44.93h1.856a5.56,5.56,0,0,0,5.567-5.567V31.94a5.536,5.536,0,0,0-4.862-5.493Zm-29.283-9.8a1.9,1.9,0,0,1,1.819-1.41H40.982a1.829,1.829,0,0,1,1.781,1.41l2.3,9.2s.148.334.223.52H20.161a2.576,2.576,0,0,0,.223-.52l2.3-9.241ZM53.118,39.363a1.838,1.838,0,0,1-1.856,1.856H14.148a1.838,1.838,0,0,1-1.856-1.856V31.94a1.838,1.838,0,0,1,1.856-1.856H51.262a1.838,1.838,0,0,1,1.856,1.856Z" transform="translate(13.476 24.375)" fill="#f73c00"/>
            <path data-name="Path 13753" d="M16.067,17.5H12.356a1.856,1.856,0,1,0,0,3.711h3.711a1.856,1.856,0,0,0,0-3.711Z" transform="translate(18.98 40.671)" fill="#f73c00"/>
            <path data-name="Path 13754" d="M23.067,17.5H19.356a1.856,1.856,0,1,0,0,3.711h3.711a1.856,1.856,0,1,0,0-3.711Z" transform="translate(37.96 40.671)" fill="#f73c00"/>
            <path data-name="Path 13755" d="M16.49,58.171H12.778A5.56,5.56,0,0,1,7.211,52.6V11.778a5.56,5.56,0,0,1,5.567-5.567H42.47a5.56,5.56,0,0,1,5.567,5.567V30.335a1.856,1.856,0,1,0,3.711,0V11.778A9.282,9.282,0,0,0,42.47,2.5H12.778A9.282,9.282,0,0,0,3.5,11.778V52.6a9.282,9.282,0,0,0,9.278,9.279H16.49a1.856,1.856,0,1,0,0-3.711Z" transform="translate(0 0)" fill="#f73c00"/>
            <path data-name="Path 13756" d="M32.48,7.356A1.838,1.838,0,0,0,30.624,5.5H8.356a1.856,1.856,0,0,0,0,3.711H30.624A1.838,1.838,0,0,0,32.48,7.356Z" transform="translate(8.134 8.134)" fill="#f73c00"/>
            <path data-name="Path 13757" d="M25.057,10.356A1.838,1.838,0,0,0,23.2,8.5H8.356a1.856,1.856,0,0,0,0,3.711H23.2A1.838,1.838,0,0,0,25.057,10.356Z" transform="translate(8.134 16.268)" fill="#f73c00"/>
            <path data-name="Path 13758" d="M8.356,11.5a1.856,1.856,0,0,0,0,3.711h7.423a1.856,1.856,0,0,0,0-3.711Z" transform="translate(8.134 24.403)" fill="#f73c00"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "ServiceManual",
        props: ["width", "height"],
    }
</script>