<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width ? width : '16.584'" :height="height ? height : '19.089'" viewBox="0 0 16.584 19.089">
        <path data-name="Icon simple-tiktok" d="M11.079.016C12.121,0,13.155.008,14.189,0a4.954,4.954,0,0,0,1.392,3.317A5.609,5.609,0,0,0,18.953,4.74V7.946a8.513,8.513,0,0,1-3.341-.772,9.827,9.827,0,0,1-1.289-.74c-.008,2.323.008,4.645-.016,6.96a6.075,6.075,0,0,1-1.074,3.134,5.926,5.926,0,0,1-4.7,2.553,5.8,5.8,0,0,1-3.245-.819,6,6,0,0,1-2.9-4.542c-.016-.4-.024-.8-.008-1.185A5.988,5.988,0,0,1,9.321,7.222c.016,1.177-.032,2.354-.032,3.531A2.729,2.729,0,0,0,5.805,12.44a3.155,3.155,0,0,0-.111,1.281A2.707,2.707,0,0,0,8.478,16a2.672,2.672,0,0,0,2.2-1.281,1.835,1.835,0,0,0,.326-.843c.08-1.424.048-2.84.056-4.263.008-3.205-.008-6.4.016-9.6Z" transform="translate(-2.369)" fill="#fff"/>
    </svg>
</template>

<script>
    export default {
        name: "Tiktok",
        props: ["color", "width", "height"],
    }
</script>