<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="78.813" height="70.517" viewBox="0 0 78.813 70.517">
        <g data-name="car sale" transform="translate(-2.5 -3.5)">
            <path data-name="Path 13770" d="M69.657,18.948l-9.5-1.908a.939.939,0,0,1-.622-.415L57.379,13.39a2.065,2.065,0,1,0-3.443,2.281l2.157,3.235a5.316,5.316,0,0,0,3.277,2.2l9.5,1.908a10.352,10.352,0,0,1,8.338,10.163,4.3,4.3,0,0,1-4.148,4.273,8.3,8.3,0,0,0-16.592.041H27.43a8.3,8.3,0,0,0-16.592-.041A4.3,4.3,0,0,1,6.69,33.176V27.618a20.25,20.25,0,0,1,.456-4.19,2.083,2.083,0,0,0-4.065-.913,23.471,23.471,0,0,0-.581,5.1v5.558a8.5,8.5,0,0,0,8.462,8.462h1a8.21,8.21,0,0,0,14.269,0H57.586a8.21,8.21,0,0,0,14.269,0h1a8.5,8.5,0,0,0,8.462-8.462A14.524,14.524,0,0,0,69.657,18.948ZM19.092,41.638A4.148,4.148,0,1,1,23.24,37.49,4.16,4.16,0,0,1,19.092,41.638Zm45.629,0a4.148,4.148,0,1,1,4.148-4.148A4.16,4.16,0,0,1,64.721,41.638Z" transform="translate(0 28.231)" fill="#ff3b00"/>
            <path data-name="Path 13772" d="M27.388,53.277A24.888,24.888,0,1,0,2.5,28.388,24.908,24.908,0,0,0,27.388,53.277Zm0-45.629a20.74,20.74,0,1,1-20.74,20.74A20.748,20.748,0,0,1,27.388,7.648Z" transform="translate(0 0)" fill="#ff3b00"/>
            <path data-name="Path 13773" d="M12.191-10.5a4.552,4.552,0,0,0-3.149,1.1,5.344,5.344,0,0,0-1.6,3.2h6.314v2.757H7.209l-.031.548v.736l.031.517H12.77V1.156h-5.3q.8,3.807,5.029,3.807a10.758,10.758,0,0,0,4.308-.893V8.081a10.087,10.087,0,0,1-4.59.924A9.467,9.467,0,0,1,5.908,6.922,9.336,9.336,0,0,1,2.791,1.156H.645V-1.632H2.493A5.935,5.935,0,0,1,2.431-2.6l.031-.83H.645V-6.191H2.728a9.529,9.529,0,0,1,3.118-5.992,9.338,9.338,0,0,1,6.345-2.2A12.161,12.161,0,0,1,17.705-13.1L16.17-9.465a15.7,15.7,0,0,0-2.021-.76A6.958,6.958,0,0,0,12.191-10.5Z" transform="translate(17.095 31.078)" fill="#ff3b00"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "ServiceSale",
        props: ["width", "height"],
    }
</script>