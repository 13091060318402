<template>
    <MetaTags :title="metaTitle !== '' ? metaTitle : `${titleCars} Αυτοκίνητα AutoBesikos ${metaBrand ? ` - ${metaBrand}` : '' }${ metaCategory ? ` - ${metaCategory}` : '' }`" :description="metaDescription" />

    <div class="vehicles-page">
        <aside v-if="!$global.isMobile" class="filters-desktop desktop-only">
            <Filters :data="filters" :active="activeFilters" :perpage="perpage" :sorting="sorting"/>
        </aside>

        <div v-else class="filters-mobile mobile-only">
            <Filters :data="filters" :active="activeFilters" :perpage="perpage" :sorting="sorting"/>
        </div>

        <div class="vehicles">
            <section class="vehicles-main">
                <div class="vehicles-search mobile-only">
                    <Search mobile />
                </div>
                <div class="vehicles-top">
                    <div class="vehicles-content">
                        <div class="vehicles-content-left">
                            <h1>{{ metaHead !== '' ? metaHead : `${titleCars} αυτοκίνητα` }}</h1>
                            <p>{{ cars.meta.total }} αποτελέσματα</p>
                        </div>
                        <div class="vehicles-content-right">
                            <div class="vehicles-content-select">
                                <p>Προβολή ανά</p>
                                <div>
                                    <select v-model="perpage">
                                        <option value="12">12</option>
                                        <option value="24" selected>24</option>
                                        <option value="48">48</option>
                                        <option value="96">96</option>
                                    </select>
                                </div>
                            </div>
                            <div class="vehicles-content-select">
                                <p>Εμφάνιση με</p>
                                <div>
                                    <select v-model="sorting">
                                        <option value="noorder" selected>Καμία επιλογή</option>
                                        <option value="alphabetic">Αλφαβητικά</option>
                                        <!-- <option value="popularity">Δημοφιλέστερα</option> -->
                                        <option value="newest">Νεότερο</option>
                                        <option value="afksousa">Τιμή Αύξουσα</option>
                                        <option value="fthinousa">Τιμή Φθίνουσα</option>
                                        <option value="kmasc">Χιλιόμετρα Αύξουσα</option>
                                        <option value="kmdesc">Χιλιόμετρα Φθίνουσα</option>
                                        <option value="earliest">Χρονολογία Αύξουσα</option>
                                        <option value="latest">Χρονολογία Φθίνουσα</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="vehicles-bottom">
                    <div class="vehicles-filters">
                        <div class="vehicles-filter" v-for="filter in convertFilters" :key="filter.category+'-'+filter.value" @click="closeFilters(filter.category, filter.subcategory, filter.id, filter.value)">
                            <p>{{ filter.subcategory ? filter.subcategory.title + ':' : '' }} {{ filter.name }}</p>
                            <Close color="#98A9B5" width="20" height="20" />
                        </div>
                    </div>
                </div>
                <div v-if="cars.data.length > 0" class="vehicles-cars" :class="$global.filtersLoading && 'vehicles-cars-loading'">
                    <div v-for="car in cars.data.filter((car, index) => index < 6)" :key="car.id" class="car-block">
                        <Car :car="car" :user="$attrs.auth.user" @favorite_car="updateFavorites" />
                    </div>
                </div>
                <div v-else class="vehicles-no-results">
                    <p>Κανένα αποτέλεσμα</p>
                </div>
                <SliderAdCars :ads="getAds" />
                <div v-if="cars.data.length > 6" class="vehicles-cars" :class="$global.filtersLoading && 'vehicles-cars-loading'">
                    <div v-for="car in cars.data.filter((car, index) => index >= 6)" :key="car.id" class="car-block">
                        <Car :car="car" :user="$attrs.auth.user" @favorite_car="updateFavorites" />
                    </div>
                </div>
            </section>

            <section class="vehicles-pagination">
                <Pagination :pages="cars.meta.links" />
            </section>

            <section v-if="$attrs.latestCars" class="vehicles-new-cars">
                <div class="vehicles-heading">
                    <h2><span>Νέες</span> αφίξεις</h2>
                    <p>Ανακαλύψτε τις νέες αφίξεις αυτοκινήτων στην AutoBesikos για απόλυτη οδηγική εμπειρία.</p>
                </div>
                <SliderNewCars :cars="$attrs.latestCars.data" noDetails :perView="perView" :user="$attrs.auth.user" @favorite_car="updateFavorites" />
            </section>

            <section class="vehicles-make-description" v-if="make_description">
                <h2>{{ activeFilters.make[0] }}</h2>
                <div v-html="make_description"></div>
            </section>

            <section class="vehicles-grid-categories vehicles-heading">
                <GridCategories :filters="filters" :popularity="popularity_strings" @update_grid="updateGrid" />
            </section>

            <section class="vehicles-bottom-text">
                <div class="vehicles-heading">
                    <h2>Με την Αξιοπιστία & την Εγγύηση της AutoBesikos</h2>
                    <p>Η AutoBesikos προσφέρει εξαιρετική ποιότητα και επιλογές σε Καινούργια & Μεταχειρισμένα επιβατικά αυτοκίνητα. Με μεγάλη προσοχή στη λεπτομέρεια και επαλήθευση της κατάστασής τους, κάθε αυτοκίνητο που προσφέρεται από την AutoBesikos είναι αξιόπιστο και έτοιμο να ανταποκριθεί στις ανάγκες σου. Επιπλέον, η εταιρεία φροντίζει για την ολοκληρωμένη εξυπηρέτησή σου, παρέχοντας υπηρεσίες ασφάλειας αυτοκινήτου, service υψηλής ποιότητας και ευέλικτες λύσεις χρηματοδότησης. Επίλεξε την AutoBesikos για να βρεις το αυτοκίνητο που σου ταιριάζει.</p>
                </div>
            </section>
        </div>
    </div>

    <!-- Favorites -->
    <RefreshPageFavorites :user="$attrs.user" :favorites_cars="favorites_cars" :favorites_cars_trigger="favorites_cars_trigger" @postChange="triggerFavorite" />
    <!-- End Favorites -->
</template>

<script>
    import MetaTags from "../Components/MetaTags.vue";
    import Button from "../Components/Button/Button.vue";
    import Range from "../Components/Slider/Range.vue";
    import SliderNewCars from "../Components/Slider/SliderNewCars.vue";
    import Car from "../Components/Car/CarBlock.vue";
    import Filters from "../Components/Filters/Filters.vue";
    import { Close } from "../Icons/Icons";
    import SliderAdCars from "../Components/Slider/SliderAdCars.vue";
    import Pagination from "../Components/Pagination/Pagination.vue";
    import GridCategories from "../Components/Grid/GridCategories.vue";
    import Search from "../Components/Search/Search.vue";
    import RefreshPageFavorites from "../Components/LocalStorage/RefreshPageFavorites.vue";
    import { computed } from "vue";

    export default {
        components: {
            Close, MetaTags,
            Car, Button, Range, SliderNewCars, SliderAdCars, Filters, Pagination, GridCategories, Search, RefreshPageFavorites
        },
        props: {
            cars: Object,
            filters: Object,
            popularity_strings: Array,
            activeFilters: Object,
            make_description: String
        },
        setup() {
            const perView = {
                '1600': { slidesPerView: 3.4 },
                '1400': { slidesPerView: 2.3 },
                '800': { slidesPerView: 1.8 },
                '600': { slidesPerView: 1.2 },
                '400': { slidesPerView: 1 },
            }

            return { perView }
        },
        data() {
            return {
                perpage: this.activeFilters.per_page ? this.activeFilters.per_page : '24',
                sorting: this.activeFilters.sorting ? this.activeFilters.sorting : 'noorder',
                titleCars: '',
                metaTitle: '',
                metaHead: '',
                metaDescription: '',
                metaBrand: '',
                metaCategory: '',
                updateChrimatodotisiFilters: 0,
                updateGridFilters: 0,
                updateSliderFilters: Object,
                favorites_cars: [],
                favorites_cars_trigger: false,
                update_favorites: 0,
            }
        },
        provide() {
            return {
                // reactive global provider
                updateFavorites: computed(() => this.update_favorites),
                updateChrimatodotisiFilter: computed(() => this.updateChrimatodotisiFilters),
                updateGridFilter: computed(() => this.updateGridFilters),
                updateSliderFilter: computed(() => this.updateSliderFilters),
            }
        },
        watch: {
            activeFilters: { handler() { this.setTheTitle(); }, deep: true },
        },
        computed: {
            convertFilters() {
                // Simplify the filters
                let arrayList = [];

                Object.entries(this.activeFilters).map((filters, index) => {

                    // If the filter is string
                    if ( typeof filters[1] === 'string' ) {
                        // Hidden Filters - Strings
                        const arrayCat = (value) => {
                            return arrayList.push({ category: filters[0], subcategory: null, id: index, value: filters[1], name: value })
                        };

                        if ( filters[0] === 'doseis' ) {
                            if ( filters[1] === 0 ) { arrayCat('Χωρίς χρηματοδότηση') }
                            if ( filters[1] > 0 ) { arrayCat(`Διάρκεια χρηματοδότησης: ${filters[1]}`) }
                        };
                        if ( filters[0] === 'prokatavoli' ) {
                            if ( filters[1] === 0 ) { arrayCat('Χωρίς προκαταβολή') }
                            if ( filters[1] > 0 ) { arrayCat(`Προκαταβολή: ${filters[1]}€`) }
                        };
                    } else if ( filters[1] ) {
                        // For Arrays
                        if ( Array.isArray(filters[1]) ) {
                            return filters[1].map((item, key) => {
                                let name = item;

                                if ( filters[0] === 'sale_price' ) { name = 'Προσφορές' };
                                // if ( filters[0] === 'offer' ) { name = 'Black Friday' };

                                return arrayList.push({ category: filters[0], subcategory: null, id: key, value: name, name: name.replace(/(-)/g, ' ') });
                            })
                        } else {
                            // For Object Arrays
                            return Object.entries(filters[1]).map((items, num) => {
                                if ( Array.isArray(items[1]) ) {
                                    return Object.values(items[1]).map((item, key) => {
                                        return arrayList.push({ category: filters[0], subcategory: { type: Array, name: items[0], title: items[0] }, id: key, value: item, name: item.replace(/(-)/g, ' ') })
                                    })
                                } else {
                                    let condition = items[0], name = filters[0];
                                    if ( items[0] === 'from' ) { condition = ' από' }
                                    if ( items[0] === 'to' ) { condition = ' μέχρι'}
                                    if ( filters[0] === 'kivika' ) { name = ' Κυβικά' }
                                    if ( filters[0] === 'price' ) { name = ' Τιμή'}
                                    if ( filters[0] === 'regiyear' ) { name = ' Χρονολογία' }
                                    if ( filters[0] === 'mileage' ) { name = ' Χιλιόμετρα' }
                                    if ( filters[0] === 'engine' ) { name = ' Κυβικά' }

                                    const pushArray = (val) => arrayList.push({ category: filters[0], subcategory: { type: Object, name: items[1], title: name+condition }, id: num, value: items[1], name: val === 'price' ? Number(items[1]).toLocaleString('el-EL') + ' €' : items[1] })

                                    if ( filters[0] === 'price' ) {
                                        if ( items[0] === 'from' ) { return pushArray('price') }
                                        if ( items[0] === 'to' ) { return pushArray('price') }
                                    } else if ( filters[0] !== 'price' ) {
                                        return pushArray()
                                    }
                                }
                            })
                        }
                    }
                });

                return arrayList
            },
            getAds() {
                const arrayAds = [

                    { title: 'Online χρηματοδότηση στην AutoBesikos από την Santander', text: 'Τώρα μπορείτε να αποκτήσετε το αυτοκίνητο που επιθυμείτε από την Autobesikos, αποφεύγοντας χρονοβόρες διαδικασίες, μέσω της online χρηματοδότησης από την Santander!', img: '/images/vehicles-services-2.jpg', img_alt: 'Online χρηματοδότηση στην AutoBesikos από την Santander', icon: '/images/santader-logo-red.png', href: '/auto-loan-calculator' },
                    { title: 'Ασφάλειες και οδική βοήθεια', text: 'Μπορείτε να ασφαλίσετε το αυτοκίνητό σας χρησιμοποιώντας ολοκληρωμένα πακέτα ασφάλισης', img: '/images/vehicles-services-3.jpg', img_alt: 'Ασφάλειες και οδική βοήθεια', icon: null, href: '/insurance' },
                    { title: 'Service Center', text: 'Στην AutoBesikos όλα τα αυτοκίνητα ελέγχονται και αποκτούν την εγγύηση της εταιρείας μας.', img: '/images/vehicles-services-1.jpg', img_alt: 'Service Center', icon: null, href: '/service' },
                    { title: 'Leasing Planet', text: 'Το Leasing διαφορετικό από αυτό που ήξερες!', img: '/images/vehicles-services-4.jpg', img_alt: 'Leasing Planet', icon: null, href: 'https://leasingplanet.gr', external: true},
                    { title: '5πλή Εγγύηση Μεταχειρισμένου', text: 'Για να σε απαλλάξουμε από το άγχος και την αβεβαιότητα που μπορεί να σου προκαλεί η αγορά ενός Μεταχειρισμένου οχήματος, σου προσφέρουμε ένα μοναδικό πακέτο 5πλής Εγγύησης!', img: '/images/vehicles-services-5.jpg', img_alt: '5πλή Εγγύηση Μεταχειρισμένου', icon: null, href: '/warranty-used-car', external: true},
                    { title: 'Μεταχειρισμένα σαν καινούρια', text: 'Στην AutoBesikos, πιστεύουμε ότι κάθε πελάτης αξίζει να απολαμβάνει την εμπειρία της οδήγησης ενός αυτοκινήτου που συνδυάζει την αξιοπιστία, την ποιότητα και την αίσθηση του καινούριου, ακόμα και όταν πρόκειται για μεταχειρισμένο.', img: '/images/home-used-new-v1.png', img_alt: 'Μεταχειρισμένα σαν καινούρια', icon: null, href: '/metaxeirismena-san-kainouria', external: true},
                    { title: 'Δικαίωμα επιστροφής χρημάτων εντός 10 ημερών', text: 'Σας προσφέρουμε την απόλυτη σιγουριά για την επιλογή σας. Πλήρης επιστροφή χρημάτων εντός 10 ημερών. Χωρίς ρίσκο, μόνο εμπιστοσύνη!', img: '/images/epistrofi-xrimaton.png', img_alt: 'Δικαίωμα επιστροφής χρημάτων εντός 10 ημερών', icon: null, href: '#', external: false},
                ]
                // Get 2 random ads
                let randomAds = arrayAds.sort(() => .5 - Math.random()).slice(0, 2);

                return randomAds 
            }
        },
        methods: {
            closeFilters(filter, subcategory, id, value) {
                if ( subcategory !== null ) {
                    if ( subcategory.type === Array ) {
                        this.activeFilters[filter][subcategory.name].splice(id, 1);
                    } else if ( subcategory.type === Object ) {
                        // Update the filter inside to update the array
                        this.updateSliderFilters = { filter: filter, id: id};
                    }
                } else {
                    if ( filter === 'doseis' || filter === 'prokatavoli' ) {
                        const tempValue = null;
                        this.activeFilters[filter] = tempValue;
                        this.updateChrimatodotisiFilters += 1;
                    } else {
                        this.activeFilters[filter].splice(id, 1);
                        // Remove the make ( marka ) if it has active items ( models )
                        if ( this.activeFilters['model'] ) {
                            if ( filter === 'make' && this.activeFilters['model'][value] && this.activeFilters['model'][value].length > 0 ) {
                                this.activeFilters['model'][value].length = 0;
                            }
                        }
                    }
                }
            },
            updateGrid() {
                this.updateGridFilters += 1;
            },
            triggerFavorite(value) { 
                // Observe the window changes and re-calculate the active favorites
                this.favorites_cars_trigger = value;
                this.favorites_cars.length = 0;
            },
            updateFavorites(id) {
                // Update the array
                if ( !this.favorites_cars.includes(id) ) {
                    this.favorites_cars.push(id);
                } else {
                    const index = this.favorites_cars.indexOf(id);
                    if ( index > -1 ) {
                        this.favorites_cars.splice(index, 1);
                    }
                }

                // global array
                if ( !this.$global.favorites_array.includes(id) ) {
                    this.$global.favorites_array.push(id);
                } else {
                    const index = this.$global.favorites_array.indexOf(id);
                    if ( index > -1 ) {
                        this.$global.favorites_array.splice(index, 1);
                    }
                }
    
                // Update between tabs - browser
                if ( this.favorites_cars.length > 0 ) {
                    this.favorites_cars_trigger = true;
                } else {
                    this.favorites_cars_trigger = false;
                }
                
                this.update_favorites += 1;
            },
            setTheTitle() {
                // Decode the Greek url 
                const tempURL = decodeURIComponent(window.location.search).split('&');
                let tempTitle = [];
                tempURL.forEach((url) => {
                    if (url.indexOf('condition') > -1) {
                        const getTitle = url.replace(/(.*)?(condition.*?=)(.*)(.*?&.*)?/g, '$3');
                        tempTitle.push(getTitle);
                    }
                });

                if (tempTitle.length === 0) {
                    this.titleCars = 'Όλα τα';
                } else {
                    this.titleCars = tempTitle.length === 2 ? `Όλα τα` : `${tempTitle[0].slice(0, -1)}α`;
                }
            },
            setMeta( ) {
                const brand = this.activeFilters['make'];
                const model = this.activeFilters['model'];
                const category = this.activeFilters['category'];
                let carsArray = [];

                if ( this.$global.current_pathname === '/black-friday-2024' || this.$global.current_pathname === '/black-friday-2024/' ) {
                    this.metaHead = 'Όλες οι Black Friday προσφορές'
                    this.metaTitle = 'Προσφορές Μεταχειρισμένα Αυτοκίνητα Black Friday 2024'
                    this.metaDescription = 'Ανακαλύψτε έως 29 Νοεμβρίου όλες τις Black Friday προσφορές έως -5000€. Μόνο στην Autobesikos'
                } else {
                    // Change the title
                    if ( brand ) {
                        brand.map((make) => {
                            if ( model ) {
                                if ( model[make] ) {
                                    model[make].map((model) => {
                                        return carsArray.push(`${this.$helper.capitalizeReplace(make)} ${this.$helper.capitalizeReplace(model)}`)
                                    })
                                } else {
                                    return carsArray.push(this.$helper.capitalizeReplace(make))
                                }
                            } else {
                                return carsArray.push(this.$helper.capitalizeReplace(make))
                            }
                        });
                        const carsString = carsArray.toString();

                        this.metaBrand = carsString.replace(/,/g, ', ');
                    }

                    let categoryArray = [];

                    if ( category ) {
                        category.map((item) => {
                            return categoryArray.push(this.$helper.capitalizeReplace(item))
                        })
                        const categoryString = categoryArray.toString();

                        this.metaCategory = categoryString.replace(/,/g, ', ');
                    }

                    if ( this.make_description ) {
                        this.metaDescription = this.make_description.replace(/<\/?[^>]+(>|$)/g, "");
                    } else {
                        if ( this.titleCars.indexOf('ργια') > -1 )  { 
                            this.metaDescription = 'Καινούργια αυτοκίνητα με την εγγύηση της AutoBesikos με δυνατότητα online χρηματοδότησης. Βρες τα καλύτερα καινούρια Αυτοκίνητα στην AutoBesikos!'
                        }

                        if ( this.titleCars.indexOf('ισμ') > -1 )  { 
                            this.metaDescription = 'Μεταχειρισμένα αυτοκίνητα με την εγγύηση της AutoBesikos με δυνατότητα online χρηματοδότησης. Βρες τα καλύτερα Μεταχειρισμένα Αυτοκίνητα στην AutoBesikos!'
                        }
                        if ( this.titleCars.indexOf('λα') > -1 )  { 
                            this.metaDescription = 'Αυτοκίνητα με την εγγύηση της AutoBesikos με δυνατότητα online χρηματοδότησης. Βρες τα καλύτερα Μεταχειρισμένα Αυτοκίνητα στην AutoBesikos!';
                        }
                    }

                }
            }
        },
        created() {
            this.setTheTitle();
            this.setMeta();
        },
        mounted() {
        },
        beforeUnmount() {
            if ( this.favorites_cars.length > 0  ) {
                axios.post(`${this.$global.origin}/account/favorites`, { ids: this.favorites_cars }).then((data) => { return data });
            }
        }
    }
</script>