<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="70.553" height="61.808" viewBox="0 0 70.553 61.808">
        <g data-name="search car" transform="translate(-2.47 -4.848)">
            <path data-name="Path 13768" d="M71.205,46.3a.653.653,0,0,0-.223.037,1.854,1.854,0,0,0-1.929,1.224,5.525,5.525,0,0,1-4.229,3.709c-4.637.853-9.348,1.447-14.059,1.855V48.158a5.408,5.408,0,0,0-1.632-3.932A5.559,5.559,0,0,0,45.2,42.594H30.365A5.557,5.557,0,0,0,24.8,48.158v4.971c-4.711-.408-9.385-1.039-14.059-1.855a5.533,5.533,0,0,1-4.488-5.453V43.336l8.68,2.893a1.68,1.68,0,0,0,.594.111,1.843,1.843,0,0,0,.556-3.6l-9.867-3.3V37.957A5.521,5.521,0,0,1,9.3,32.987l.556-.3A8.756,8.756,0,0,0,11.632,31.5H26.581a1.855,1.855,0,1,0,0-3.709H14.266s.148-.3.223-.482L19.236,13.03A5.618,5.618,0,0,1,24.5,9.209H30.29a1.855,1.855,0,1,0,0-3.709H24.5a9.257,9.257,0,0,0-8.791,6.343L10.965,26.124V26.2l-5.3-5.3a1.862,1.862,0,1,0-2.634,2.634l5.527,5.527a2.029,2.029,0,0,1-.371.3l-.556.3a9.241,9.241,0,0,0-5.119,8.309V57.469a7.441,7.441,0,0,0,7.419,7.419H12.04a7.353,7.353,0,0,0,6.64-4.117l2.151-4.3a152.094,152.094,0,0,0,16.915.964,152.094,152.094,0,0,0,16.915-.964l2.151,4.3a7.385,7.385,0,0,0,6.64,4.08h2.114a7.441,7.441,0,0,0,7.419-7.419V48.158A1.837,1.837,0,0,0,71.131,46.3ZM15.453,59.1a3.665,3.665,0,0,1-3.3,2.04H10.037a3.72,3.72,0,0,1-3.709-3.709V53.2a8.928,8.928,0,0,0,3.821,1.706c2.263.408,4.563.779,6.862,1.076L15.49,59.064Zm13.094-5.712v-5.23A1.86,1.86,0,0,1,30.4,46.3H45.239a1.74,1.74,0,0,1,1.3.556,1.889,1.889,0,0,1,.556,1.3v5.23A154.7,154.7,0,0,1,28.547,53.388Zm37.094,7.753H63.527a3.665,3.665,0,0,1-3.3-2.04l-1.558-3.079c2.3-.3,4.6-.668,6.862-1.076A8.791,8.791,0,0,0,69.35,53.2v4.266a3.72,3.72,0,0,1-3.709,3.709Z" transform="translate(0 1.768)" fill="#ff3b00"/>
            <path data-name="Path 13769" d="M27.539,38.2a16.613,16.613,0,0,0,10.349-3.709L47.2,43.805a1.863,1.863,0,0,0,1.3.556,1.8,1.8,0,0,0,1.3-.556,1.836,1.836,0,0,0,0-2.634l-9.311-9.311a16.367,16.367,0,0,0,3.709-10.349A16.682,16.682,0,1,0,27.5,38.2ZM18.377,12.35a12.949,12.949,0,0,1,9.162-3.784,13.1,13.1,0,0,1,9.2,3.784,12.589,12.589,0,0,1,3.821,9.162A12.991,12.991,0,1,1,18.377,12.35Z" transform="translate(22.671)" fill="#ff3b00"/>
        </g>
    </svg>

</template>

<script>
    export default {
        name: "ServiceSearch",
        props: ["width", "height"],
    }
</script>